.App {
  text-align: center;
}


@media (prefers-reduced-motion: no-preference) {
/*   .App-logo {
    animation: App-logo-spin infinite 20s linear;
  } */
}

/* .App-header {
  background-color: #F2F5F7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #34495E;
} */
